<template>
  <div>
    <div class="simulation">
      <div class="container-fluid">
        <div class="card mb-4 shadow" :class="{ 'bg-dark': isDarkTheme }">
          <form>
            <div class="mb-3">
              <label for="merchant" class="form-label" :class="{ 'text-white': isDarkTheme }">
                Merchant
              </label>
              <Dropdown id="merchant" v-model="selectedMerchantHash" checkmark
                :options="fetchedMerchants" optionValue="id"
                optionLabel="label" :filter="true" placeholder="Select Merchant"
                class="w-full" :class="{ 'p-input-dark': isDarkTheme }"
                style="display: flex;" aria-required="true">
                <template #option="slotProps">
                  <div class="flex align-items-center">
                    {{ slotProps.option.label }}
                  </div>
                </template>
              </Dropdown>
              <div id="merchantHelp" class="form-text" :class="{ 'text-secondary': isDarkTheme }">
                Merchant which will perform payment.
              </div>
            </div>

            <div class="mb-3">
              <label for="provider" class="form-label" :class="{ 'text-white': isDarkTheme }">
                Permitted provider
              </label>
              <Dropdown id="provider" v-model="selectedProviderId" checkmark
                :options="fetchedProviders" optionValue="id"
                optionLabel="label" :filter="true" placeholder="Select Provider"
                class="w-full" :class="{ 'p-input-dark': isDarkTheme }"
                style="display: flex;">
                <template #option="slotProps">
                  <div class="flex align-items-center">
                    {{ slotProps.option.label }}
                  </div>
                </template>
              </Dropdown>
              <div id="providerHelp" class="form-text" :class="{ 'text-secondary': isDarkTheme }">
                Choose, either client will be able to choose any available provider or only one specific provider.
              </div>
            </div>

            <div class="mb-3">
              <label for="clientId" class="form-label" :class="{ 'text-white': isDarkTheme }">
                Client ID
              </label>

              <input type="text" class="form-control" id="clientId"
                v-model="clientId" aria-describedby="clientIdHelp"
                :class="{ 'bg-dark text-light': isDarkTheme }" />
              <div id="clientIdHelp" class="form-text" :class="{ 'text-secondary': isDarkTheme }">
                Merchant's client identifier.
              </div>
            </div>

            <div class="mb-3">
              <label for="additionalData" class="form-label" :class="{ 'text-white': isDarkTheme }">
                Additional Data
              </label>

              <textarea class="form-control" id="additionalData"
                v-model="additionalData" aria-describedby="additionalDataHelp"
                :class="{ 'bg-dark text-light': isDarkTheme }"
                rows=6 spellcheck="false">
              </textarea>
              <div id="additionalDataHelp" class="form-text" :class="{ 'text-secondary': isDarkTheme }">
                Additional data for client identification.
              </div>
            </div>

            <div class="mb-3">
              <label for="nonce" class="form-label" :class="{ 'text-white': isDarkTheme }">
                Nonce
              </label>

              <div style="display: flex;">
                <input type="text" class="form-control" id="nonce"
                  v-model="nonce" aria-describedby="nonceHelp"
                  :class="{ 'bg-dark text-light': isDarkTheme }" />
                <Button icon="pi pi-refresh" style="border-radius: 0.5vh; margin-left: 1vh;" aria-label="Refresh"
                  @click="refreshForm" />
              </div>
              <div id="nonceHelp" class="form-text" :class="{ 'text-secondary': isDarkTheme }">
                Transaction unique nonce.
              </div>
            </div>

            <div class="mb-3">
              <label for="amount" class="form-label" :class="{ 'text-white': isDarkTheme }">
                Amount
              </label>

              <input type="number" class="form-control" id="amount"
                v-model="amount" aria-describedby="amountHelp"
                :class="{ 'bg-dark text-light': isDarkTheme }" />
              <div id="amountHelp" class="form-text" :class="{ 'text-secondary': isDarkTheme }">
                Transaction amount.
              </div>
            </div>

            <div class="mb-3">
              <label for="currency" class="form-label" :class="{ 'text-white': isDarkTheme }">
                Currency
              </label>

              <input type="text" class="form-control" id="currency"
                v-model="currency" aria-describedby="currencyHelp"
                :class="{ 'bg-dark text-light': isDarkTheme }" />
              <div id="currencyHelp" class="form-text" :class="{ 'text-secondary': isDarkTheme }">
                Transaction currency.
              </div>
            </div>

            <div class="flex items-center mb-3">
              <Checkbox v-model="isDeposit" id="isDeposit" aria-describedby="isDepositHelp" indeterminate binary></Checkbox>
              <label for="isDeposit" class="ml-2" :class="{ 'text-light': isDarkTheme }" style="margin-left: 0.5rem;">Deposit</label>
              <div id="isDepositHelp" class="form-text" :class="{ 'text-secondary': isDarkTheme }">
                Rather transaction is deposit or withdrawal.
              </div>
            </div>

            <div class="flex items-center mb-5">
              <Checkbox v-model="isRedirect" id="isRedirect" aria-describedby="isRedirectHelp" indeterminate binary></Checkbox>
              <label for="isRedirect" class="ml-2" :class="{ 'text-light': isDarkTheme }" style="margin-left: 0.5rem;">Redirect To Payment Page</label>
              <div id="isRedirectHelp" class="form-text" :class="{ 'text-secondary': isDarkTheme }">
                Rather redirect to payment page after initialising transaction or just showing the link.
              </div>
            </div>

            <div class="flex items-center" :class="{ 'mb-4': !isProcessing && isSuccess }">
              <Button class="w-50" style="margin-left: auto; margin-right: auto; display: block;"
              @click="initTransaction" :disabled="isProcessing || isSuccess">
                <span v-if="isProcessing" class="spinner-border" style="width: 1rem; height: 1rem;" role="status"
                  aria-hidden="true"></span>
                <span v-else-if="isSuccess">
                  <i class="fas fa-check"></i>
                </span>
                <span v-else>Initialize</span>
              </Button>
            </div>

            <div v-if="!isProcessing && isSuccess"
            class="flex items-center" :class="{ 'text-light': isDarkTheme }"
            style="margin-left: auto; margin-right: auto; display: table;">
              <Button :label="linkToTransaction" link @click="redirectToPaymentPage" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';

export default { 
  name: 'SimulatePaymentPage',
  components: {
    Button,
    Dropdown,
    Checkbox,
  },
  data() {
    return {
      selectedMerchantHash: '',
      selectedProviderId: 0,
      clientId: '1356895449',
      additionalData: '{\n\t"fullName": "Bob Test",\n\t"partnerId": 18764119,\n\t"systemId": 4820,\n\t"phoneNumber": "09663408517"\n}',
      nonce: 'test_' + new Date().toISOString().replace(/[-:T.]/g, '').slice(0, 14),
      amount: 201.99,
      currency: 'PHP',
      isDeposit: false,
      isRedirect: false,
      linkToTransaction: null,
      isSuccess: false,
      isProcessing: false,
    };
  },
  computed: {
    ...mapState(['isDarkTheme']),
    ...mapState('providers', ['providers']),
    ...mapState('merchants', ['merchants']),
    fetchedMerchants() {
      return this.merchants.map(merchant => ({
        id: merchant.hash,
        label: merchant.name
      }));
    },
    fetchedProviders() {
      return [{ id: 0, label: "All" }].concat(
        this.providers.map(provider => ({
        id: provider.id,
        label: provider.name
      })));
    },
  },
  async created() {
    await this.fetchMerchants();
    await this.fetchProviders();
  },
  mounted() {
    document.title = "Create Transaction";
  },
  methods: {
    ...mapActions('providers', ['fetchProviders']),
    ...mapActions('merchants', ['fetchMerchants']),
    ...mapActions('transactions', ['initializeTransaction']),
    async initTransaction() {
      this.isProcessing = true;

      const transaction = {
        paymentData: {
          nonce: this.nonce,
          amount: this.amount,
          currency: this.currency,
          providerId: this.selectedProviderId
        },
        merchantData: {
          merchantHash: this.selectedMerchantHash,
          clientId: this.clientId,
          additionalData: JSON.parse(this.additionalData),
        },
        cashIn: this.isDeposit,
      };

      const result = await this.initializeTransaction(transaction);
      
      const link = "https://yapay.io/payment?id=" + result.transactionReferenceId;
      this.linkToTransaction = link;
      if (this.isRedirect) {
        window.open(link, "_blank");
      }
      
      this.isProcessing = false;
      this.isSuccess = true;
    },
    redirectToPaymentPage() {
      window.open(this.linkToTransaction, "_blank");
    },
    refreshForm() {
      this.nonce = 'test_' + new Date().toISOString().replace(/[-:T.]/g, '').slice(0, 14);
      this.isProcessing = false;
      this.isSuccess = false;
      this.linkToTransaction = null;
    },
  }
}

</script>

<style scoped>

.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.card {
  border-radius: 12px;
  padding: 20px;
  max-width: max-content;
  margin-left: auto;
  margin-right: auto;
}

.bg-dark {
  background-color: #222 !important;
}

.simulation {
  padding: 60px 10px 10px;
  display: flex;
  flex-direction: column;
}

</style>