<template>
  <div class="log-viewer" :class="{ 'dark-theme': isDarkTheme }">
    <div v-if="isLoading" class="loading-container">
      <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
      <p>Loading logs...</p>
    </div>

    <div v-else-if="error" class="error-container">
      <i class="pi pi-exclamation-triangle"></i>
      <p>{{ error }}</p>
      <Button label="Retry" icon="pi pi-refresh" @click="fetchLogs" />
    </div>

    <div v-else-if="parsedLogs" class="logs-container">
      <!-- Quick Stats Bar -->
      <div class="stats-bar">
        <div v-if="parsedLogs.transaction.status" class="stat-item" :class="{ 'error': parsedLogs.summary.errors > 0 }">
          <i class="pi pi-circle-fill" :class="getStatusIconClass(parsedLogs.transaction.status)"></i>
          <div class="stat-content">
            <div class="stat-label">Final Status</div>
            <div class="stat-value">{{ parsedLogs.transaction.status }}</div>
          </div>
        </div>
        <div class="stat-item">
          <i class="pi pi-clock"></i>
          <div class="stat-content">
            <div class="stat-label">Total Duration</div>
            <div class="stat-value">{{ formatDuration(parsedLogs.summary.totalDuration) }}</div>
          </div>
        </div>
        <div class="stat-item">
          <i class="pi pi-sync"></i>
          <div class="stat-content">
            <div class="stat-label">API Calls</div>
            <div class="stat-value">{{ parsedLogs.summary.apiCalls }}</div>
          </div>
        </div>
        <div class="stat-item" :class="{ 'warning': parsedLogs.summary.pollingAttempts > 10 }">
          <i class="pi pi-refresh"></i>
          <div class="stat-content">
            <div class="stat-label">Polling Attempts</div>
            <div class="stat-value">{{ parsedLogs.summary.pollingAttempts }}</div>
          </div>
        </div>
      </div>

      <!-- Details and History Cards Row -->
      <div class="cards-row">
        <!-- Transaction Details Card -->
        <div class="info-card flex-1">
          <div class="card-header">
            <i class="pi pi-info-circle"></i>
            <h3>Transaction Details</h3>
          </div>
          <div v-if="parsedLogs.transaction.provider" class="details-grid">
            <div class="detail-item">
              <span class="detail-label">Amount</span>
              <span class="detail-value">{{ parsedLogs.transaction.amount }}</span>
            </div>
            <div class="detail-item">
              <span class="detail-label">Provider</span>
              <span class="detail-value">{{ parsedLogs.transaction.provider }}</span>
            </div>
            <div class="detail-item">
              <span class="detail-label">Created</span>
              <span class="detail-value">{{ formatDate(parsedLogs.transaction.created) }}</span>
            </div>
            <div class="detail-item">
              <span class="detail-label">Updated</span>
              <span class="detail-value">{{ formatDate(parsedLogs.transaction.updated) }}</span>
            </div>
            <div class="detail-item">
              <span class="detail-label">Merchant</span>
              <span class="detail-value">{{ parsedLogs.transaction.merchant }}</span>
            </div>
            <div class="detail-item">
              <span class="detail-label">Callback Status</span>
              <span class="detail-value" :class="getCallbackClass(parsedLogs.transaction.callbackStatus)">
                {{ parsedLogs.transaction.callbackStatus }}
              </span>
            </div>
          </div>
          <div v-else class="empty-details">No details recorded (in logs)</div>
        </div>

        <!-- Status History Card -->
        <div class="info-card flex-1">
          <div class="card-header">
            <i class="pi pi-history"></i>
            <h3>Status Changes</h3>
          </div>
          <div class="status-timeline" v-if="parsedLogs.summary.statusChanges.length > 0">
            <div v-for="(change, index) in parsedLogs.summary.statusChanges"
                 :key="index"
                 class="status-change">
              <div class="status-marker" :class="getStatusClass(change.to)"></div>
              <div class="status-info">
                <div class="status-text">{{change.from}} -> {{ change.to }}</div>
                <div class="status-time">{{ formatTime(change.timestamp) }}</div>
              </div>
              <div v-if="index < parsedLogs.summary.statusChanges.length - 1" class="status-connector"></div>
            </div>
          </div>
          <div v-else class="empty-status">No status changes recorded (in logs)</div>
        </div>
      </div>

      <!-- Timeline Section -->
      <div class="info-card timeline-section">
        <div class="card-header">
          <i class="pi pi-list"></i>
          <h3>Processing Timeline</h3>
        </div>
        <div class="timeline">
          <div v-for="(event, index) in parsedLogs.timeline"
               :key="index"
               class="timeline-item"
               :class="[`level-${event.level}`, `stage-${getStageClass(event.stage)}`]">
            <div class="timeline-marker" :class="getStageClass(event.stage)">
              <i :class="getStageIcon(event.stage)"></i>
            </div>
            <div class="timeline-content">
              <div class="timeline-header">
                <span class="timeline-time">{{ formatTime(event.timestamp) }}</span>
                <span class="timeline-app">{{ event.application }}</span>
                <span class="timeline-stage">{{ event.stage }}</span>
              </div>
              <div class="timeline-body">
                <div v-if="!event.jsonContent" class="timeline-message">{{ event.message }}</div>
                <div v-if="event.source" class="timeline-source">{{ event.source }}</div>

                <!-- JSON Content -->
                <template v-if="event.jsonContent">
                  <div v-if="event.jsonContent.request" class="json-section">
                    <JsonDisplay
                        :content="event.jsonContent.request"
                        label="Request"
                        :is-dark-theme="isDarkTheme"
                        :initially-expanded="false"
                    />
                  </div>
                  <div v-if="event.jsonContent.response" class="json-section">
                    <JsonDisplay
                        :content="event.jsonContent.response"
                        label="Response"
                        :is-dark-theme="isDarkTheme"
                        :initially-expanded="false"
                    />
                  </div>
                  <div v-if="event.jsonContent.content" class="json-section">
                    <JsonDisplay
                        :content="event.jsonContent.content"
                        label="JSON"
                        :is-dark-theme="isDarkTheme"
                        :initially-expanded="false"
                    />
                  </div>
                  <div v-if="event.jsonContent.transactionUpdate" class="json-section">
                    <JsonDisplay
                        :content="event.jsonContent.transactionUpdate"
                        label="Transaction Update"
                        :is-dark-theme="isDarkTheme"
                        :initially-expanded="false"
                    />
                  </div>
                  <div v-if="event.jsonContent.authData" class="json-section">
                    <JsonDisplay
                        :content="event.jsonContent.authData"
                        label="Redirection Data"
                        :is-dark-theme="isDarkTheme"
                        :initially-expanded="false"
                    />
                  </div>
                </template>

                <!-- Other Details -->
                <div v-if="event.details" class="details-grid">
                  <div v-for="(value, key) in event.details"
                       :key="key"
                       class="detail-item">
                    <span class="detail-label">{{ formatDetailKey(key) }}</span>
                    <span class="detail-value">{{ value }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Errors Section -->
      <div v-if="parsedLogs.errors.length > 0" class="info-card error-section">
        <div class="card-header">
          <i class="pi pi-exclamation-triangle error-icon"></i>
          <h3>Errors ({{ parsedLogs.errors.length }})</h3>
        </div>
        <div class="errors-container">
          <div v-for="(error, index) in parsedLogs.errors"
               :key="index"
               class="error-card">
            <div class="error-header">
              <span class="error-time">{{ formatTime(error.timestamp) }}</span>
              <span class="error-app">{{ error.application }}</span>
            </div>
            <div class="error-message">{{ error.message }}</div>
            <div v-if="error.source" class="error-source">{{ error.source }}</div>
            <div v-if="error.exception" class="error-details">
              <div class="error-type">{{ error.exception.type }}</div>
              <div class="error-reason">{{ error.exception.message }}</div>
              <Accordion v-if="error.exception.stackTrace">
                <AccordionTab header="Stack Trace">
                  <pre class="stack-trace">{{ error.exception.stackTrace }}</pre>
                </AccordionTab>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LogParser } from './LogParser';
import Button from "primevue/button";
import JsonDisplay from './JsonDisplayComponent';
import {mapActions} from "vuex";
import { formatDuration } from './LogParser';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';

export default {
  name: 'LogViewer',
  components: {
    Button,
    Accordion,
    AccordionTab,
    JsonDisplay
  },
  props: {
    transactionId: {
      type: [Number, String],
      required: true
    },
    isDarkTheme: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      error: null,
      parsedLogs: null
    };
  },
  created() {
    this.fetchLogs();
  },
  methods: {
    ...mapActions('transactions', ['getTransactionLogs']),
    async fetchLogs() {
      this.isLoading = true;
      this.error = null;

      try {
        const response = await this.getTransactionLogs(this.transactionId);
        const parser = new LogParser(response);
        this.parsedLogs = parser.parse();
        console.log('Parsed logs:', this.parsedLogs);
      } catch (error) {
        this.error = 'Failed to fetch logs: ' + error.message;
        console.error('Error fetching logs:', error);
      } finally {
        this.isLoading = false;
      }
    },

    formatTime(date) {
      return date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        fractionalSecondDigits: 3
      });
    },

    formatDate(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString);
      return date.toLocaleString('en-US', {
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
    },

    formatDetailKey(key) {
      return key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
    },

    formatDuration,

    getStatusClass(status) {
      const statusLower = status?.toLowerCase() ?? '';
      if (statusLower.includes('success')) return 'status-success';
      if (statusLower.includes('pending')) return 'status-pending';
      if (statusLower.includes('error') || statusLower.includes('fail')) return 'status-error';
      return 'status-unknown';
    },

    getStatusIconClass(status) {
      const statusLower = status?.toLowerCase() ?? '';
      if (statusLower.includes('success')) return 'status-success-icon';
      if (statusLower.includes('pending')) return 'status-pending-icon';
      return 'status-error-icon';
    },

    getCallbackClass(status) {
      if (!status) return 'status-unknown';
      const words = status.split(' ');
      if (words.length < 2) return 'status-unknown';
      const tries = words[1].split('/');
      if (tries?.length < 2) return 'status-unknown';
      if (tries[0] === tries[1]) return 'status-success';
      return 'status-pending';
      //return 'status-error';
    },

    getStageClass(stage) {
      return stage?.toLowerCase().replace(/\s+/g, '-') ?? 'unknown';
    },

    getStageIcon(stage) {
      const icons = {
        'Initialization': 'pi pi-flag',
        'Transaction Creation': 'pi pi-plus',
        'API Request': 'pi pi-send',
        'API Response': 'pi pi-reply',
        'Polling': 'pi pi-sync',
        'Callback Processing': 'pi pi-bell'
      };
      return icons[stage] || 'pi pi-circle';
    },
  }
};
</script>

<style scoped>
.log-viewer {
  padding: 0 2rem 2rem;
  height: 100%;
  overflow-y: auto;
  background-color: var(--surface-ground);
}

/* Loading and Error States */
.loading-container,
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  gap: 1rem;
  min-height: 200px;
  background-color: var(--surface-card);
  border-radius: 0.5rem;
}

.json-section {
  margin-top: 0.5rem;
}

.headers-section {
  margin-top: 0.5rem;
}

.http-details {
  display: flex;
  gap: 0.75rem;
  align-items: center;
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: var(--surface-section);
  border-radius: 0.25rem;
  font-family: monospace;
  font-size: 0.9rem;
  flex-wrap: wrap;
}

.http-method {
  font-weight: 600;
  color: var(--primary-color);
  background-color: var(--primary-50);
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  white-space: nowrap;
}

.http-uri {
  color: var(--text-color);
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 200px;
}

.http-status {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-weight: 500;
  white-space: nowrap;
}

.http-time {
  color: var(--text-color-secondary);
  white-space: nowrap;
}

/* HTTP Status Colors */
.http-status.status-success {
  background-color: var(--green-50);
  color: var(--green-600);
}

.http-status.status-error {
  background-color: var(--red-50);
  color: var(--red-600);
}

.http-status.status-warning {
  background-color: var(--yellow-50);
  color: var(--yellow-600);
}

.http-status.status-info {
  background-color: var(--blue-50);
  color: var(--blue-600);
}

/* Dark Theme Adjustments */
.dark-theme {
  .http-details {
    background-color: var(--surface-card);
  }

  .http-method {
    background-color: var(--primary-900);
    color: var(--primary-200);
  }

  .http-status.status-success {
    background-color: var(--green-900);
    color: var(--green-200);
  }

  .http-status.status-error {
    background-color: var(--red-900);
    color: var(--red-200);
  }

  .http-status.status-warning {
    background-color: var(--yellow-900);
    color: var(--yellow-200);
  }

  .http-status.status-info {
    background-color: var(--blue-900);
    color: var(--blue-200);
  }
}

/* Timeline adjustments for JSON content */
.timeline-item {
  .timeline-content {
    max-width: 100%;
  }

  .timeline-body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

/* JSON Content Sections */
.json-section, .headers-section {
  border-radius: 0.25rem;
  overflow: hidden;
}

.json-section + .json-section,
.json-section + .headers-section {
  margin-top: 0.75rem;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .http-details {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .http-uri {
    width: 100%;
    overflow-x: auto;
    white-space: pre-wrap;
  }
}

/* Code highlighting */
.code-block {
  background: var(--surface-ground);
  padding: 0.75rem;
  border-radius: 0.25rem;
  overflow-x: auto;
  font-family: monospace;
  font-size: 0.9rem;
  line-height: 1.4;
  color: var(--text-color);
  border: 1px solid var(--surface-border);
}

.code-block pre {
  margin: 0;
  white-space: pre-wrap;
  word-break: break-word;
}

/* Syntax highlighting */
.key {
  color: var(--primary-color);
}

.string {
  color: var(--green-600);
}

.number {
  color: var(--blue-500);
}

.boolean {
  color: var(--purple-500);
}

.null {
  color: var(--text-color-secondary);
  font-style: italic;
}

.dark-theme {
  .key {
    color: var(--primary-400);
  }

  .string {
    color: var(--green-400);
  }

  .number {
    color: var(--blue-400);
  }

  .boolean {
    color: var(--purple-400);
  }

  .null {
    color: var(--text-color-secondary);
  }
}

/* Expandable sections */
.expandable-section {
  border: 1px solid var(--surface-border);
  border-radius: 0.25rem;
  margin: 0.5rem 0;
}

.expandable-header {
  padding: 0.5rem;
  background-color: var(--surface-section);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  user-select: none;
}

.expandable-header:hover {
  background-color: var(--surface-hover);
}

.expandable-content {
  padding: 0.5rem;
  border-top: 1px solid var(--surface-border);
}

/* Loading states */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.dark-theme .loading-overlay {
  background-color: rgba(0, 0, 0, 0.7);
}

.timeline-item:hover .copy-button,
.expandable-header:hover .copy-button {
  opacity: 1;
}

/* Stats Bar */
.stats-bar {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.stat-item {
  flex: 1;
  min-width: 200px;
  background-color: var(--surface-card);
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease;
}

.stat-item:hover {
  transform: translateY(-2px);
}

.stat-item i {
  font-size: 1.5rem;
  color: var(--primary-color);
}

.stat-item.error i {
  color: var(--red-500);
}

.stat-item.warning i {
  color: var(--yellow-500);
}

.stat-content {
  display: flex;
  flex-direction: column;
}

.stat-label {
  color: var(--text-color-secondary);
  font-size: 0.875rem;
}

.stat-value {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--text-color);
}

/* Cards Layout */
.cards-row {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1;
  min-width: 300px;
}

.info-card {
  background-color: var(--surface-card);
  border-radius: 0.5rem;
  padding: 1.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 1rem;
}

.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid var(--surface-border);
}

.card-header i {
  font-size: 1.25rem;
  margin-right: 0.75rem;
  color: var(--primary-color);
}

.card-header h3 {
  margin: 0;
  font-size: 1.1rem;
  color: var(--text-color);
  font-weight: 600;
}

/* Details Grid */
.details-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 1rem;
}

.detail-item {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.detail-label {
  color: var(--text-color-secondary);
  font-size: 0.875rem;
  word-wrap: anywhere;
}

.detail-value {
  color: var(--text-color);
  font-weight: 500;
  font-size: 0.92rem;
  word-wrap: break-word;
}

/* Status Timeline */
.status-timeline {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem 0;
}

.status-change {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  position: relative;
}

.status-marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: var(--primary-color);
  flex-shrink: 0;
}

.status-connector {
  position: absolute;
  left: 0.45rem;
  top: 1rem;
  bottom: -1rem;
  width: 2px;
  background-color: var(--surface-border);
}

.status-info {
  flex: 1;
}

.status-text {
  font-weight: 500;
  color: var(--text-color);
}

.status-time {
  font-size: 0.875rem;
  color: var(--text-color-secondary);
}

/* Timeline Section */
.timeline {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.timeline-item {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  background-color: var(--surface-section);
  border-radius: 0.5rem;
  position: relative;
}

.timeline-marker {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: var(--surface-ground);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.timeline-marker i {
  font-size: 1rem;
  color: var(--primary-color);
}

.timeline-content {
  flex: 1;
  min-width: 0; /* Ensures proper text truncation */
}

.timeline-header {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
}

.timeline-time {
  font-family: monospace;
  color: var(--text-color-secondary);
}

.timeline-app {
  font-size: 0.875rem;
  color: var(--primary-color);
  font-weight: 500;
}

.timeline-stage {
  font-size: 0.875rem;
  color: var(--text-color-secondary);
  padding: 0.125rem 0.5rem;
  background-color: var(--surface-ground);
  border-radius: 1rem;
}

.timeline-body {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.timeline-message {
  color: var(--text-color);
  word-break: break-word;
}

.timeline-source {
  font-size: 0.875rem;
  color: var(--text-color-secondary);
  word-wrap: anywhere;
  white-space: pre-wrap;
}

/* HTTP Details */
.http-details {
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
  padding: 0.5rem;
  background-color: var(--surface-ground);
  border-radius: 0.25rem;
  font-family: monospace;
  font-size: 0.875rem;
}

.http-method {
  font-weight: 600;
  color: var(--primary-color);
}

.http-url {
  color: var(--text-color);
  word-break: break-all;
}

.http-status {
  padding: 0.125rem 0.5rem;
  border-radius: 1rem;
  font-weight: 500;
}

.http-time {
  color: var(--text-color-secondary);
}

/* Error Section */
.errors-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.error-card {
  background-color: var(--surface-section);
  border-radius: 0.5rem;
  padding: 1rem;
  border-left: 4px solid var(--red-500);
}

.error-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.error-time {
  font-family: monospace;
  color: var(--text-color-secondary);
}

.error-app {
  font-weight: 500;
  color: var(--primary-color);
}

.error-message {
  color: var(--red-500);
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.error-source {
  font-size: 0.875rem;
  color: var(--text-color-secondary);
  margin-bottom: 0.5rem;
}

.error-details {
  background-color: var(--surface-ground);
  border-radius: 0.25rem;
  padding: 1rem;
}

.error-type {
  font-family: monospace;
  color: var(--primary-color);
  margin-bottom: 0.25rem;
}

.error-reason {
  color: var(--text-color);
  margin-bottom: 0.5rem;
}

.stack-trace {
  font-family: monospace;
  font-size: 0.875rem;
  white-space: pre-wrap;
  word-break: break-all;
  background-color: var(--surface-card);
  padding: 1rem;
  border-radius: 0.25rem;
  margin: 0;
  max-height: 300px;
  overflow-y: auto;
}

/* Status Colors */
.status-success { background-color: var(--green-100); color: var(--green-900); }
.status-pending { background-color: var(--yellow-100); color: var(--yellow-900); }
.status-error { background-color: var(--red-100); color: var(--red-900); }
.status-unknown { background-color: var(--surface-200); color: var(--surface-900); }

.dark-theme .status-success { background-color: var(--green-900); color: var(--green-100); }
.dark-theme .status-pending { background-color: var(--yellow-900); color: var(--yellow-100); }
.dark-theme .status-error { background-color: var(--red-900); color: var(--red-100); }
.dark-theme .status-unknown { background-color: var(--surface-700); color: var(--surface-100); }

/* Status Icons */
.status-success-icon { color: var(--green-500) !important; }
.status-pending-icon { color: var(--yellow-500) !important; }
.status-error-icon { color: var(--red-500) !important; }

/* Responsive Adjustments */
@media (max-width: 768px) {
  .stats-bar {
    grid-template-columns: repeat(2, 1fr);
  }

  .stat-item {
    min-width: 150px;
  }

  .cards-row {
    flex-direction: column;
  }

  .flex-1 {
    min-width: 100%;
  }

  .timeline-header {
    flex-direction: column;
    gap: 0.25rem;
  }

  .http-details {
    flex-direction: column;
    gap: 0.25rem;
  }
}

@media (max-width: 480px) {
  .stats-bar {
    grid-template-columns: 1fr;
  }

  .details-grid {
    grid-template-columns: 1fr;
  }
}
</style>