<template>
  <div class="json-content" :class="{ 'dark-theme': isDarkTheme }" :style="indentStyle">
    <div class="json-header" @click="toggleExpanded" :class="{ 'json-header-expandable': isExpandable }">
      <i :class="getHeaderIcon" class="header-icon"></i>
      <span class="json-label">{{ label }}</span>
      <div v-if="copyable" class="copy-button">
        <Button type="button" icon="pi pi-copy" class="p-button-text p-button-rounded copy-json-button"
                size="small" @click.stop="copyContent"/>
      </div>
    </div>
    <div v-if="isExpanded" class="json-body">
      <template v-if="Array.isArray(content)">
        <div v-for="(value, index) in content" :key="index" class="json-field">
          <div class="field-name">{{ index }}:</div>
          <div v-if="isObject(value)" class="field-value">
            <JsonDisplay
                :content="value"
                :label="index"
                :is-dark-theme="isDarkTheme"
                :level="level + 1"
                :key="`json-${index}-${level}`"
                :initially-expanded="false"
            />
          </div>
          <div v-else class="field-value" :class="getValueClass(value)">
            {{ formatValue(value) }}
          </div>
        </div>
      </template>
      <template v-else-if="typeof content === 'object'">
        <div v-for="(value, key) in content" :key="key" class="json-field">
          <div class="field-name">{{ formatKey(key) }}:</div>
          <div v-if="isObject(value)" class="field-value">
            <JsonDisplay
                :content="value"
                :label="key"
                :is-dark-theme="isDarkTheme"
                :level="level + 1"
                :key="`json-${key}-${level}`"
                :initially-expanded="false"
            />
          </div>
          <div v-else class="field-value" :class="getValueClass(value)">
            {{ formatValue(value) }}
          </div>
        </div>
      </template>
      <pre v-else class="json-raw">{{ formatValue(content) }}</pre>
    </div>
  </div>
</template>

<script>

import Button from 'primevue/button';

export default {
  name: 'JsonDisplay',
  components: {
    Button
  },
  props: {
    content: {
      type: [Object, Array, String],
      required: true
    },
    label: {
      type: String,
      default: 'JSON'
    },
    isDarkTheme: {
      type: Boolean,
      default: false
    },
    level: {
      type: Number,
      default: 0
    },
    initiallyExpanded: {
      type: Boolean,
      default: false
    },
    copyable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isExpanded: this.initiallyExpanded
    };
  },
  computed: {
    isExpandable() {
      return typeof this.content === 'object' ||
          (typeof this.content === 'string' && this.content.length > 50);
    },
    getHeaderIcon() {
      return {
        'pi': true,
        'pi-chevron-right': !this.isExpanded && this.isExpandable,
        'pi-chevron-down': this.isExpanded && this.isExpandable,
        'pi-code': !this.isExpandable
      };
    },
    indentStyle() {
      return {
        marginLeft: this.level > 0 ? `${this.level * 1.5}rem` : '0'
      };
    }
  },
  methods: {
    toggleExpanded() {
      if (this.isExpandable) {
        this.isExpanded = !this.isExpanded;
      }
    },
    isObject(value) {
      return value !== null && typeof value === 'object';
    },
    formatKey(key) {
      return key.trim();
    },
    formatValue(value) {
      if (value === null) return 'null';
      if (value === undefined) return 'undefined';
      if (typeof value === 'string') {
        try {
          const parsed = JSON.parse(value);
          if (typeof parsed === 'object') {
            return JSON.stringify(parsed, null, 2);
          }
        } catch (e) {
          console.error('Failed to parse JSON:', e);
        }
      }
      return String(value);
    },
    getValueClass(value) {
      if (value === null || value === undefined) return 'null-value';
      if (typeof value === 'number') return 'number-value';
      if (typeof value === 'boolean') return 'boolean-value';
      return 'string-value';
    },
    copyContent() {
      const content = typeof this.content === 'object'
          ? JSON.stringify(this.content, null, 2)
          : this.content;
      navigator.clipboard.writeText(content).then(() => {
        this.$toast?.add?.({
          severity: 'success',
          summary: 'Copied',
          detail: 'Content copied to clipboard',
          life: 3000
        });
      }).catch(err => {
        console.error('Failed to copy:', err);
      });
    }
  }
};
</script>

<style scoped>

.copy-json-button {
  width: 2.8rem;
  height: 1.4rem;
}

.json-content {
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', monospace;
  font-size: 0.9rem;
  margin: 0.25rem 0;
  border-radius: 0.25rem;
  background-color: var(--surface-ground);
}

.json-header {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
  user-select: none;
  border-radius: 0.25rem;
  background-color: var(--surface-section);
  border: 1px solid var(--surface-border);
}

.json-header:hover {
  background-color: var(--surface-hover);
}

.json-header-expandable {
  cursor: pointer;
}

.header-icon {
  margin-right: 0.5rem;
  font-size: 0.9rem;
  color: var(--primary-color);
  width: 1rem;
}

.json-label {
  flex: 1;
  font-weight: 500;
  color: var(--primary-color);
}

.json-body {
  padding: 0.5rem;
  border: 1px solid var(--surface-border);
  border-top: none;
  border-radius: 0 0 0.25rem 0.25rem;
}

.json-body .json-content {
  margin-left: 0.5rem;
  border-left: 2px solid var(--surface-border);
  padding-left: 0.5rem;
}

.json-field {
  display: flex;
  margin: 0.25rem 0;
  gap: 0.5rem;
}

.field-name {
  color: var(--primary-color);
  font-weight: 500;
  min-width: 120px;
  padding-right: 0.5rem;
}

.field-value {
  flex: 1;
  min-width: 0;
}

.string-value {
  color: var(--green-600);
}

.number-value {
  color: var(--blue-500);
}

.boolean-value {
  color: var(--purple-500);
}

.null-value {
  color: var(--text-color-secondary);
  font-style: italic;
}

.json-raw {
  margin: 0;
  padding: 0.5rem;
  background-color: var(--surface-section);
  border-radius: 0.25rem;
  overflow-x: auto;
  color: var(--text-color);
}

.dark-theme {
  .string-value {
    color: var(--green-400);
  }
  .number-value {
    color: var(--blue-400);
  }
  .boolean-value {
    color: var(--purple-400);
  }
  .field-name {
    color: var(--primary-400);
  }
}

.copy-button {
  opacity: 0;
  transition: opacity 0.2s ease;
}

.json-header:hover .copy-button {
  opacity: 1;
}

.json-field {
  display: flex;
  padding: 0.25rem 0;
  border-bottom: 1px solid var(--surface-border);
}

.json-field:last-child {
  border-bottom: none;
}

.field-value {
  flex: 1;
  min-width: 0;
  word-break: break-all;
}

.json-raw {
  white-space: pre-wrap;
  word-break: break-all;
}
</style>
