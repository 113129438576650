<template>
  <div class="providers">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="mb-4">Providers</h2>
          <div v-if="isLoading" class="text-center">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <template v-else-if="!error">
            <div class="card mb-4 shadow" :class="{ 'bg-dark': isDarkTheme }">
              <div class="card-body">
                <ul class="list-group">
                  <li class="list-group-item d-flex flex-column flex-md-row justify-content-between align-items-center"
                    v-for="collab in collabs" :key="collab.id" :class="{ 'bg-dark text-white': isDarkTheme }">
                    <div class="mb-2 mb-md-0">
                      <span><strong v-if="this.isAdmin">[{{ collab.merchant.name }}] </strong>{{ collab.provider.name
                        }}</span>
                    </div>
                    <div class="d-flex align-items-center"
                      v-if="isRegistered(collab.provider.id, collab.merchant.id) !== 0">
                      <button class="btn btn-sm btn-secondary me-2" @click="openCollabSettingsModal(collab)"
                        :disabled="isSettingsLoading(collab)">
                        <span v-if="isSettingsLoading(collab)" class="spinner-border" style="width: 1rem; height: 1rem;" role="status"
                          aria-hidden="true"></span>
                        <span v-else>Settings</span>
                      </button>
                      <button class="btn btn-sm" :class="collab.isEnabled ? 'btn-danger' : 'btn-success'"
                        @click="toggleCollabActiveness(collab)" :disabled="isActivenessLoading(collab)">
                        <span v-if="isActivenessLoading(collab)" class="spinner-border" style="width: 1rem; height: 1rem;" role="status"
                          aria-hidden="true"></span>
                        <span v-else>{{ collab.isEnabled ? 'Disable' : 'Enable' }}</span>
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </template>
          <div v-if="error" class="text-danger mt-4">{{ error }}</div>
          <ModalWindow :show="showCollabSettingsModal" @close="closeCollabSettingsModal" :isDarkTheme="isDarkTheme">
            <template #header>
              <h6>{{ selectedCollabSettings.merchantName }}</h6>
              <h5 class="modal-title">
                {{ selectedCollabSettings.providerName }}
              </h5>
            </template>
            <template #body>
              <div v-if="selectedCollab" class="modal-body-content">
                <form>
                  <!-- Collab Validation URL -->
                  <div class="mb-3">
                    <label for="collabValidationUrl" class="form-label">
                      Collab Validation URL
                    </label>

                    <input type="text" class="form-control" id="collabValidationUrl"
                      v-model="selectedCollabSettings.collabValidationUrl" aria-describedby="cashOutHelp"
                      :class="{ 'bg-dark text-light': isDarkTheme }" />
                    <div id="cashOutHelp" class="form-text" :class="{ 'text-secondary': isDarkTheme }">
                      The URL to validate client ability to use this collab.
                    </div>
                  </div>

                  <!-- Toggle Switches for Various Settings -->
                  <div class="form-check form-switch mb-3">
                    <input type="checkbox" class="form-check-input" id="isEnabled"
                      v-model="selectedCollabSettings.isEnabled" />
                    <label class="form-check-label" for="isEnabled">
                      Enable this collaboration
                      <small class="form-text d-block" :class="{ 'text-secondary': isDarkTheme }">Toggle to enable or
                        disable
                        this collab.</small>
                    </label>
                  </div>

                  <div v-if="selectedCollabSettings.isCashInEnabled != null" class="form-check form-switch mb-3">
                    <input type="checkbox" class="form-check-input" id="isCashInEnabled"
                      v-model="selectedCollabSettings.isCashInEnabled" />
                    <label class="form-check-label" for="isCashInEnabled">
                      Enable cash in for this collaboration
                      <small class="form-text d-block" :class="{ 'text-secondary': isDarkTheme }">Toggle to enable or
                        disable cash in for this collab.
                      </small>
                    </label>
                  </div>

                  <div v-if="selectedCollabSettings.isCashOutEnabled != null" class="form-check form-switch mb-3">
                    <input type="checkbox" class="form-check-input" id="isCashOutEnabled"
                      v-model="selectedCollabSettings.isCashOutEnabled" />
                    <label class="form-check-label" for="isCashOutEnabled">
                      Enable cash out for this collaboration
                      <small class="form-text d-block" :class="{ 'text-secondary': isDarkTheme }">Toggle to enable or
                        disable cash out for this collab.
                      </small>
                    </label>
                  </div>

                  <div class="form-check form-switch mb-3">
                    <input type="checkbox" class="form-check-input" id="validateCashOut"
                      v-model="selectedCollabSettings.validateCashOut" />
                    <label class="form-check-label" for="validateCashOut">
                      Validate Cash Out
                      <small class="form-text d-block" :class="{ 'text-secondary': isDarkTheme }">Enable or disable cash
                        out
                        validation for this
                        collab.</small>
                    </label>
                  </div>

                  <div class="form-check form-switch mb-3">
                    <input type="checkbox" class="form-check-input" id="validateCashIn"
                      v-model="selectedCollabSettings.validateCashIn" />
                    <label class="form-check-label" for="validateCashIn">
                      Validate Cash In
                      <small class="form-text d-block" :class="{ 'text-secondary': isDarkTheme }">Enable or disable cash
                        in
                        validation for this
                        collab.</small>
                    </label>
                  </div>

                  <div class="form-check form-switch mb-3">
                    <input type="checkbox" class="form-check-input" id="isMerchantCallbackEnabled"
                      v-model="selectedCollabSettings.isMerchantCallbackEnabled" />
                    <label class="form-check-label" for="isMerchantCallbackEnabled">
                      Merchant Callback Enabled
                      <small class="form-text d-block" :class="{ 'text-secondary': isDarkTheme }">Enable or disable
                        merchant
                        callback for this collab.</small>
                    </label>
                  </div>

                  <div class="form-check form-switch mb-3">
                    <input type="checkbox" class="form-check-input" id="isCreditingToClientsEnabled"
                      v-model="selectedCollabSettings.isCreditingToClientsEnabled" />
                    <label class="form-check-label" for="isCreditingToClientsEnabled">
                      Crediting to Clients Enabled
                      <small class="form-text d-block" :class="{ 'text-secondary': isDarkTheme }">Enable or disable
                        crediting
                        to clients for this
                        collab.</small>
                    </label>
                  </div>

                  <div class="form-check form-switch mb-3">
                    <input type="checkbox" class="form-check-input" id="isWithdrawFromClientsEnabled"
                      v-model="selectedCollabSettings.isWithdrawFromClientsEnabled" />
                    <label class="form-check-label" for="isWithdrawFromClientsEnabled">
                      Withdraw from Clients Enabled
                      <small class="form-text d-block" :class="{ 'text-secondary': isDarkTheme }">Enable or disable
                        withdrawing from clients for this
                        collab.</small>
                    </label>
                  </div>

                  <!-- Pay types -->
                  <div v-if="selectedCollabSettings.payTypes && selectedCollabSettings.payTypes.length > 0">
                    <h5 class="modal-title" style="margin-block: 1rem;">Pay Types</h5>
                    <div class="mb-3" 
                    v-for="payType in selectedCollabSettings.payTypes" :key="payType.code">
                      <div class="form-check form-switch mb-3">
                        <input type="checkbox" class="form-check-input" id="payType"
                          v-model="payType.isEnabled" />
                        <label class="form-check-label" for="payType">
                          {{ payType.name }} ({{ payType.code }})
                          <small class="form-text d-block" :class="{ 'text-secondary': isDarkTheme }">
                            Used for: {{ payType.cashIn ? 'Deposit ' : '' }} {{ payType.cashIn && payType.cashOut ? 'and' : '' }} {{ payType.cashOut ? 'Withdrawal' : ''}}
                          </small>
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </template>

            <template #footer>
              <div class="d-flex justify-content-between w-100">
                <div v-if="saveError" class="text-danger m-2 w-100">{{ saveError }}</div>
                <button class="btn w-100" :class="{ 'btn-success': isSaveSuccess, 'btn-primary': !isSaveSuccess }"
                  @click="saveCollabSettings" :disabled="isSaveLoading || isSaveSuccess">
                  <span v-if="isSaveLoading" class="spinner-border" style="width: 1rem; height: 1rem;" role="status"
                    aria-hidden="true"></span>
                  <span v-else-if="isSaveSuccess">
                    <i class="fas fa-check"></i>
                  </span>
                  <span v-else>Save</span>
                </button>
              </div>
            </template>

          </ModalWindow>
        </div>
      </div>
    </div>
  </div>
  <Toast />
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ModalWindow from '@/components/ModalWindow.vue';
import Toast from 'primevue/toast';
import { useToast } from "primevue/usetoast";

export default {
  name: 'ProvidersPage',
  components: {
    ModalWindow,
    Toast,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      isLoading: false,
      error: null,
      showCollabSettingsModal: false,
      selectedProvider: null,
      selectedCollab: null,
      selectedCollabSettings: null,
      settingsLoading: {},
      activenessLoading: {},
      isSaveLoading: false,
      saveError: null,
      isSaveSuccess: false,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('providers', ['providers']),
    ...mapState('collabs', ['collabs']),
    isDarkTheme() {
      return this.$store.state.isDarkTheme;
    },
    isAdmin() {
      return this.user && this.user.roles.includes('Admin');
    },
  },
  async created() {
    try {
      this.isLoading = true;
      if (this.isAdmin) {
        const providers = await this.fetchProviders();
        await this.fetchCollabsByProviderIds(providers.map(provider => provider.id));
      }
      else {
        await this.fetchCollabsByMerchantId(this.user.merchantId);
      }
    } catch (error) {
      this.error = error.message || 'Failed to fetch data. Please try again.';
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },
  mounted() {
    document.title = "Providers";
  },
  methods: {
    ...mapActions('providers', ['fetchProviders', 'updateProviderActiveness']),
    ...mapActions('collabs', ['fetchCollabsByMerchantId', 'fetchCollabsByProviderIds', 'fetchCollabSettings', 'updateCollabSettings']),

    isActivenessLoading(collab) {
      return this.activenessLoading[collab.id] || false;
    },

    isSettingsLoading(collab) {
      return this.settingsLoading[collab.id] || false;
    },

    isRegistered(providerId, merchantId) {
      return this.collabs.filter(collab => collab.merchant.id === merchantId && collab.provider.id === providerId).length;
    },

    async toggleCollabActiveness(collab) {
      try {
        if (collab) {
          this.activenessLoading[collab.id] = true;
          await this.updateProviderActiveness({ providerId: collab.provider.id, isEnabled: !collab.isEnabled });
          collab.isEnabled = !collab.isEnabled;
          this.toast.add({ severity: 'success', summary: 'Success', detail: `[${collab.merchant.name}] ${collab.provider.name} is now ${collab.isEnabled ? 'enabled' : 'disabled'}`, life: 3000 });
        }
      } catch (error) {
        console.error(error);
        this.error = 'Failed to update provider activeness. Please try again.';
        this.toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to update provider activeness: ' + error.message, life: 5500 });
      } finally {
        this.activenessLoading[collab.id] = false;
      }
    },

    async openCollabSettingsModal(collab) {
      try {
        if (collab) {
          this.settingsLoading[collab.id] = true;
          this.selectedCollab = collab;
          const response = await this.fetchCollabSettings(collab.id);
          this.selectedCollabSettings = response;
          this.selectedCollabSettings.providerName = collab.provider.name;
          this.selectedCollabSettings.merchantName = collab.merchant.name;
          this.showCollabSettingsModal = true;
        }
      } catch (error) {
        this.error = 'Failed to fetch collab settings. Please try again.';
        this.toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to fetch collab settings: ' + error.message, life: 5500 });
        console.error(error);
      } finally {
        this.settingsLoading[collab.id] = false;
      }
    },

    closeCollabSettingsModal() {
      this.showCollabSettingsModal = false;
      this.selectedProvider = null;
      this.selectedCollab = null;
      this.selectedCollabSettings = null;
      this.saveError = null;
      this.isSaveLoading = false;
    },

    async saveCollabSettings() {
      try {
        this.isSaveLoading = true;
        this.saveError = null;
        await this.updateCollabSettings({ collabId: this.selectedCollab.id, settings: this.selectedCollabSettings });

        const index = this.collabs.findIndex(collab => collab.id === this.selectedCollab.id);
        if (index !== -1) {
          this.collabs[index].isEnabled = this.selectedCollabSettings.isEnabled;
        }

        this.isSaveLoading = false;
        this.isSaveSuccess = true;
        setTimeout(() => {
          this.isSaveSuccess = false;
          this.closeCollabSettingsModal();
        }, 1000);
      } catch (error) {
        this.saveError = 'Failed to save';
        this.toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to save collab settings: ' + error.message, life: 5500 });
        console.error(error);
      } finally {
        this.isSaveLoading = false;
      }
    },
  },
};

</script>

<style scoped>
.providers {
  padding: 60px 20px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}

.list-group-item {
  background-color: var(--bg-color);
  color: var(--text-color);
}

.modal {
  z-index: 1000;
  display: flex;
  position: fixed;
}
</style>
