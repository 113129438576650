<template>
  <div class="callback-settings">
    <div class="container-fluid">
      <h2 class="mb-4">Callback Settings</h2>
      <template v-if="!user.merchantId">
        <p class="text-danger">You don't have permission to view this page.</p>
      </template>
      <template v-else>
        <div class="table-container" :class="{ 'dark-theme': isDarkTheme }">
          <DataTable v-model:editingRows="editingRows" :value="callbackUrls" dataKey="id" :loading="isLoading"
            responsiveLayout="stack" :rowHover="true" :resizableColumns="true" editMode="row"
            @row-edit-save="onRowEditSave">
            <template #header>
              <div class="table-header">
                <Button icon="pi pi-plus" label="Add New" class="p-button-success mr-2"
                        @click="openAddDialog" />
              </div>
            </template>
            <Column field="collabId" header="Collab" :options="collabs" optionLabel="label" optionValue="id">
              <template #editor="{ data, field }">
                <Dropdown v-model="data[field]" :options="collabs" optionLabel="label" optionValue="id"
                  placeholder="Select a Collaboration" class="w-full" />
              </template>
            </Column>
            <Column field="callbackUrl" header="Callback URL">
              <template #body="{ data }">
                  <span
                      style="word-wrap: anywhere; white-space: pre-wrap; font-size: 0.9rem;">{{
                      data.callbackUrl }}</span>
              </template>
              <template #editor="{ data, field }">
                <InputText v-model="data[field]" autofocus class="w-full" />
              </template>
            </Column>
            <Column field="isEnabled" header="Enabled">
              <template #body="{ data }">
                <i class="pi"
                   :class="{ 'pi-check-circle text-green-500': data.isEnabled, 'pi-times-circle text-red-500': !data.isEnabled }"></i>
              </template>
              <template #editor="{ data, field }">
                <ToggleButton v-model="data[field]" onIcon="pi pi-check" offIcon="pi pi-times" class="w-full" />
              </template>
            </Column>
            <Column field="maxAttempts" header="Max Attempts">
              <template #editor="{ data, field }">
                <InputNumber v-model="data[field]" :min="1" :max="12" class="w-full" />
              </template>
            </Column>
            <Column field="addSignature" header="Sign Body">
              <template #body="{ data }">
                <i class="pi"
                  :class="{ 'pi-check-circle text-green-500': data.addSignature, 'pi-times-circle text-red-500': !data.addSignature }"></i>
              </template>
              <template #editor="{ data, field }">
                <ToggleButton v-model="data[field]" onIcon="pi pi-check" offIcon="pi pi-times" class="w-full" />
              </template>
            </Column>
            <Column field="transactionTypeId" header="Type">
              <template #body="{ data }">
                {{ getTransactionTypeName(data.transactionTypeId) }}
              </template>
              <template #editor="{ data, field }">
                <Dropdown v-model="data[field]" :options="transactionTypes" optionLabel="name" optionValue="id"
                  placeholder="Select a Transaction Type" class="w-full" />
              </template>
            </Column>
            <Column :rowEditor="true" style="width: 10%; min-width: 2rem"
                    bodyStyle="text-align:center"></Column>
            <Column style="width: 2rem">
              <template #body="{ data }">
                <Button icon="pi pi-trash" class="p-button-outlined p-button-danger" @click="confirmDelete(data)" />
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </div>

    <Dialog v-model:visible="addDialogVisible" header="Add Callback URL" :modal="true" class="p-fluid callback-dialog"
    :maximizable="true" :style="{ width: '40rem' }" :breakpoints="{ '1199px': '50vw', '775px': '90vw' }">
      <div class="p-grid p-ai-center">
        <div class="p-col-12">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-link"></i></span>
            <InputText id="callbackUrl" v-model="newCallbackUrl.callbackUrl" placeholder="Callback URL"
              :class="{ 'p-invalid': submitted && !newCallbackUrl.callbackUrl }" />
          </div>
          <small class="p-error" v-if="submitted && !newCallbackUrl.callbackUrl">Callback URL is required.</small>
          <div class="p-text-secondary">Enter the URL where the callback will be sent.</div>
        </div>
        <div class="p-col-12">
          <div class="p-inputgroup mt-3">
            <span class="p-inputgroup-addon"><i class="pi pi-sitemap"></i></span>
            <Dropdown id="collabId" v-model="newCallbackUrl.collabId" :options="collabs" optionLabel="label"
              optionValue="id" placeholder="Select a Collaboration"
              :class="{ 'p-invalid': submitted && !newCallbackUrl.collabId }" />
          </div>
          <small class="p-error" v-if="submitted && !newCallbackUrl.collabId">Collaboration is required.</small>
          <div class="p-text-secondary p-mt-1">Choose the collaboration associated with this callback.</div>
        </div>
        <div class="p-col-12 p-md-6">
          <div class="p-inputgroup mt-3">
            <span class="p-inputgroup-addon"><i class="pi pi-replay"></i></span>
            <InputNumber id="maxAttempts" v-model="newCallbackUrl.maxAttempts" :min="1" :max="12"
              placeholder="Max Attempts" :class="{ 'p-invalid': submitted && !newCallbackUrl.maxAttempts }" />
          </div>
          <small class="p-error" v-if="submitted && !newCallbackUrl.maxAttempts">Max attempts is required.</small>
          <div class="p-text-secondary p-mt-1">Set the maximum number of callback attempts.</div>
        </div>
        <div class="p-col-12 p-md-6">
          <div class="p-inputgroup mt-3">
            <span class="p-inputgroup-addon"><i class="pi pi-tags"></i></span>
            <Dropdown id="transactionTypeId" v-model="newCallbackUrl.transactionTypeId" :options="transactionTypes"
              optionLabel="name" optionValue="id" placeholder="Select Transaction Type" />
          </div>
          <div class="p-text-secondary p-mt-1">Specify the transaction type for this callback.</div>
        </div>
        <div class="p-col-12">
          <div class="p-field-checkbox mt-3">
            <Checkbox id="isEnabled" v-model="newCallbackUrl.isEnabled" :binary="true" />
            <label for="isEnabled" class="ms-1">Enable Callback</label>
          </div>
          <div class="p-text-secondary ms-4">Enable or disable the callback.</div>
        </div>
        <div class="p-col-12">
          <div class="p-field-checkbox mt-3">
            <Checkbox id="addSignature" v-model="newCallbackUrl.addSignature" :binary="true" />
            <label for="addSignature" class="ms-1">Add Signature</label>
          </div>
          <div class="p-text-secondary ms-4">Include a signature with the callback payload.</div>
        </div>
      </div>
      <template #footer>
        <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="addDialogVisible = false" />
        <Button label="Save" icon="pi pi-check" class="p-button-success" @click="saveNewCallbackUrl" />
      </template>
    </Dialog>

    <ConfirmDialog>
      <template #message="slotProps">
        <div class="flex align-items-center">
          <i :class="slotProps.message.icon" style="font-size: 1.5rem; margin-right: 0.5rem;"></i>
          <span>{{ slotProps.message.message }}</span>
        </div>
      </template>
    </ConfirmDialog>
    <ConfirmPopup></ConfirmPopup>
    <Toast />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import Dialog from 'primevue/dialog';
import Checkbox from 'primevue/checkbox';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import ToggleButton from 'primevue/togglebutton';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import Toast from 'primevue/toast';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";

export default {
  name: 'CallbackSettingsPage',
  components: {
    Dialog,
    Checkbox,
    DataTable,
    Column,
    InputText,
    InputNumber,
    ToggleButton,
    Dropdown,
    Button,
    ConfirmDialog,
    ConfirmPopup,
    Toast,
  },
  setup() {
    const confirm = useConfirm();
    const toast = useToast();

    return { confirm, toast };
  },
  data() {
    return {
      collabs: [],
      transactionTypes: [
        { id: null, name: 'All' },
        { id: 1, name: 'Cash In' },
        { id: 2, name: 'Cash Out' },
      ],
      isLoading: false,
      errorMessage: null,
      editingRows: [],
      addDialogVisible: false,
      submitted: false,
      newCallbackUrl: this.initNewCallbackUrl(),
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('callbacks', ['callbackUrls']),
    isDarkTheme() {
      return this.$store.state.isDarkTheme;
    },
  },
  async created() {
    await this.loadCallbackUrls();
    await this.loadCollabs();
  },
  mounted() {
    document.title = "Callbacks";
  },
  methods: {
    ...mapActions('callbacks', [
      'fetchCallbackUrlsByMerchantId',
      'createCallbackUrl',
      'updateCallbackUrl',
      'deleteCallbackUrl',
    ]),
    ...mapActions('collabs', [
      'fetchCollabsByMerchantId',
      'fetchCollabsByProviderIds']),

    async loadCallbackUrls() {
      try {
        this.isLoading = true;
        this.errorMessage = null;
        await this.fetchCallbackUrlsByMerchantId(this.user.merchantId);
      } catch (error) {
        this.errorMessage = error.message;
      } finally {
        this.isLoading = false;
      }
    },

    async loadCollabs() {
      try {
        this.errorMessage = null;
        this.isLoading = true;
        const response = await this.fetchCollabsByMerchantId(this.user.merchantId);
        this.collabs = response.map(collab => ({
          id: collab.id,
          label: `${collab.merchant.name} - ${collab.provider.name}`,
        }));
      } catch (error) {
        this.errorMessage = error.message;
      } finally {
        this.isLoading = false;
      }
    },

    getTransactionTypeName(id) {
      const type = this.transactionTypes.find(type => type.id === id);
      return type ? type.name : 'Unknown';
    },

    initNewCallbackUrl() {
      return {
        collabId: null,
        callbackUrl: '',
        maxAttempts: 3,
        isEnabled: true,
        addSignature: true,
        transactionTypeId: null,
      };
    },

    openAddDialog() {
      this.newCallbackUrl = this.initNewCallbackUrl();
      this.submitted = false;
      this.addDialogVisible = true;
    },

    async saveNewCallbackUrl() {
      this.submitted = true;

      if (this.newCallbackUrl.collabId && this.newCallbackUrl.callbackUrl) {
        this.confirm.require({
          message: 'Are you sure you want to create a new callback URL?',
          header: 'Confirmation',
          acceptIcon: 'pi pi-check',
          rejectIcon: 'pi pi-times',
          acceptClass: 'p-button-success',
          accept: async () => {
            try {
              this.toast.add({ severity: 'info', summary: 'Adding...', detail: 'Adding new callback URL', life: 3000 });
              const createdCallbackUrl = await this.createCallbackUrl({ merchantId: this.user.merchantId, callbackUrl: this.newCallbackUrl });
              this.callbackUrls.unshift(createdCallbackUrl);
              this.toast.add({ severity: 'success', summary: 'Successful', detail: 'Callback URL created', life: 3000 });
              this.addDialogVisible = false;
            } catch (error) {
              this.toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to create callback URL: ' + error.message, life: 3000 });
            }
          },
        });
      }
    },

    async onRowEditSave(event) {
      const { newData, index } = event;

      if (newData.id) {
        this.confirm.require({
          message: 'Are you sure you want to update this callback URL?',
          header: 'Confirmation',
          acceptIcon: 'pi pi-check',
          rejectIcon: 'pi pi-times',
          acceptClass: 'p-button-warning',
          accept: async () => {
            try {
              this.toast.add({ severity: 'info', summary: 'Updating...', detail: 'Updating callback URL', life: 3000 });
              await this.updateCallbackUrl({ merchantId: this.user.merchantId, callbackUrlId: newData.id, callbackUrl: newData });
              this.toast.add({ severity: 'success', summary: 'Successful', detail: 'Callback URL updated', life: 3000 });
            } catch (error) {
              this.toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to update callback URL: ' + error.message, life: 3000 });
            }
          },
          reject: () => {
            // Revert changes
            this.callbackUrls[index] = { ...this.callbackUrls[index], ...event.data };
          }
        });
      } else {
        this.confirm.require({
          message: 'Are you sure you want to create a new callback URL?',
          header: 'Confirmation',
          acceptIcon: 'pi pi-check',
          rejectIcon: 'pi pi-times',
          acceptClass: 'p-button-success',
          accept: async () => {
            try {
              this.toast.add({ severity: 'info', summary: 'Adding...', detail: 'Adding new callback URL', life: 3000 });
              const createdCallbackUrl = await this.createCallbackUrl({ merchantId: this.user.merchantId, callbackUrl: newData });
              this.callbackUrls[index] = createdCallbackUrl;
              this.toast.add({ severity: 'success', summary: 'Successful', detail: 'Callback URL created', life: 3000 });
            } catch (error) {
              this.toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to create callback URL: ' + error.message, life: 3000 });
            }
          },
          reject: () => {
            // Remove the new row
            this.callbackUrls = this.callbackUrls.filter(url => url.id > 0);
          }
        });
      }
    },

    confirmDelete(callbackUrl) {
      this.confirm.require({
        message: 'Are you sure you want to delete this callback URL?',
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptIcon: 'pi pi-trash',
        rejectIcon: 'pi pi-times',
        acceptClass: 'p-button-danger',
        accept: async () => {
          try {
            this.toast.add({ severity: 'info', summary: 'Deleting...', detail: 'Deleting callback URL', life: 3000 });
            await this.deleteCallbackUrl({ callbackUrlId: callbackUrl.id, merchantId: this.user.merchantId });
            this.callbackUrls = this.callbackUrls.filter(url => url.id !== callbackUrl.id);
            this.toast.add({ severity: 'success', summary: 'Successful', detail: 'Callback URL deleted', life: 3000 });
          } catch (error) {
            this.toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to delete callback URL: ' + error.message, life: 3000 });
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.callback-settings {
  padding: 60px 10px 10px;
}

.table-container {
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.2rem;
  border: 2px solid #e0e0e0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
}

.table-container.dark-theme {
  background-color: #18181B;
  color: rgb(248, 248, 248);
  border-color: #41414e;
}

.table-header {
  display: flex;
  justify-content: flex-end;
}

.callback-dialog {
  width: 500px;
}

.callback-dialog .p-inputgroup {
  margin-bottom: 0.1rem;
}

.callback-dialog .p-field-checkbox {
  margin-bottom: 0.2rem;
}

</style>