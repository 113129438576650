<template>
  <div class="edit-transaction" :class="{ 'dark-theme': isDarkTheme }">
    <div v-if="isLoading" class="loading-container">
      <i class="pi pi-spin pi-spinner loading-spinner"></i>
      <p class="loading-text">Processing request...</p>
    </div>

    <div v-else class="edit-content">
      <!-- Status Update Section -->
      <div class="info-card status-card">
        <div class="card-header">
          <div class="header-icon-container">
            <i class="pi pi-shield header-icon"></i>
          </div>
          <div>
            <h3>Transaction Status Management</h3>
            <p class="card-subtitle">Manual status override controls</p>
          </div>
        </div>
        <div class="card-content">

          <div class="status-form">
            <div class="state-container">
              <div class="state-pair">
                <label>CURRENT STATE</label>
                <div class="status-indicator elevated" :class="getStatusClass(currentStatusId)">
                  <i class="pi" :class="getStatusIcon(currentStatusId)"></i>
                  <span class="padding-left">{{ getStatusLabel(currentStatusId) }}</span>
                  <div class="status-glow"></div>
                </div>
              </div>
              <i class="pi pi-arrow-right transition-arrow"></i>
              <div class="state-pair">
                <label>NEW STATE</label>
                <Dropdown
                    v-model="selectedStatus"
                    :options="transactionStatusOptions"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Select Transition"
                    class="w-full status-dropdown"
                    :class="{ 'p-input-dark': isDarkTheme }"
                >
                  <template #value="slotProps">
                    <div class="status-option" v-if="slotProps.value">
                      <i class="pi" :class="getStatusIcon(slotProps.value)"></i>
                      <span class="padding-left">{{ getStatusLabel(slotProps.value) }}</span>
                    </div>
                    <span v-else>{{ slotProps.placeholder }}</span>
                  </template>
                  <template #option="slotProps">
                    <div class="status-option">
                      <i class="pi" :class="getStatusIcon(slotProps.option.value)"></i>
                      <span class="padding-left">{{ slotProps.option.label }}</span>
                      <span class="status-code"> ({{ slotProps.option.value }})</span>
                    </div>
                  </template>
                </Dropdown>
              </div>
            </div>
            <div class="actions">
              <Button
                  label="Commit State Change"
                  icon="pi pi-lock"
                  @click="handleStatusUpdate"
                  :loading="isUpdatingStatus"
                  :disabled="!selectedStatus || selectedStatus === currentStatusId"
                  class="p-button-raised confirm-button"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Validation Results Section -->
      <div class="info-card validation-card">
        <div class="card-header">
          <div class="header-icon-container">
            <i class="pi pi-server header-icon"></i>
          </div>
          <div>
            <h3>Validation Orchestration</h3>
            <p class="card-subtitle">System validation controls</p>
          </div>
          <Button
              v-if="validationResults.length > 0"
              icon="pi pi-sync"
              class="p-button-text refresh-button"
              @click="fetchValidationResults"
              :loading="isLoadingValidations"
              tooltip="Refresh Validation States"
          />
        </div>
        <div class="card-content">

          <div v-if="isLoadingValidations" class="loading-state">
            <i class="pi pi-spin pi-spinner"></i>
            <span class="padding-left">Loading validation results...</span>
          </div>

          <div v-else-if="validationResults.length === 0" class="empty-state">
            <i class="pi pi-lock-open empty-icon"></i>
            <span>No active validation constraints</span>
          </div>

          <div v-else class="validation-list">
            <TransitionGroup name="validation-item">
              <div v-for="validation in validationResults"
                   :key="validation.validationId"
                   class="validation-item">
                <div class="validation-meta">
                  <div class="validation-type">
                    <i :class="validation.isAwaitable ? 'pi pi-clock' : 'pi pi-link'"></i>
                    <span v-if="validation.isAwaitable"
                          class="validation-id">AVR-{{validation.validationId }}</span>
                    <span v-else class="validation-id">VR-{{validation.validationId }}</span>
                  </div>
                  <div class="validation-endpoint">
                    <code class="url-text">{{ validation.validationUrl }}</code>
                  </div>
                </div>
                <div class="validation-controls">
                  <div class="state-indicator">
              <span :class="['status-tag', getValidationStatusClass(validation.status)]">
                {{ validationStatusOptions.find(opt => opt.value === validation.status)?.label || 'UNKNOWN' }}
              </span>
                  </div>
                  <div class="control-group">
                    <Dropdown
                        v-model="validation.status"
                        :options="validationStatusOptions"
                        optionLabel="label"
                        optionValue="value"
                        placeholder="Set State"
                        class="p-button-sm validation-dropdown"
                    />
                    <Button
                        label="Update"
                        icon="pi pi-save"
                        class="p-button-sm p-button-raised"
                        :loading="isUpdatingValidation === validation.validationId"
                        @click="handleSetValidationStatus(validation.validationId, validation.status)"
                        tooltip="Persist State"
                    />
                    <Button
                        v-if="validation.isAwaitable"
                        label="Retry Validation Request"
                        icon="pi pi-replay"
                        class="p-button-sm p-button-outlined"
                        :loading="isRetryingValidation === validation.validationId"
                        @click="handleRetryValidation(validation.validationId)"
                        tooltip="Force Retry"
                    />
                  </div>
                </div>
              </div>
            </TransitionGroup>
          </div>
        </div>
      </div>

      <!-- Queue Operations Section -->
      <div class="info-card queue-card">
        <div class="card-header">
          <div class="header-icon-container">
            <i class="pi pi-list header-icon"></i>
          </div>
          <div>
            <h3>Queue Operations</h3>
            <p class="card-subtitle">Operations with queues</p>
          </div>

        </div>
        <div class="card-content">
          <div class="queue-grid">
            <!-- Transaction Queue Card -->
            <div class="queue-operation-card">
              <div class="queue-icon-wrapper">
                <i class="pi pi-inbox"></i>
              </div>
              <div class="queue-content">
                <h4>Transaction Queue</h4>
                <p>Add the transaction to the processing queue to restart from the beginning.
                  Before adding, ensure that <span class="highlighted-text">all</span> validation
                  checks have passed and the transaction status <span class="highlighted-text">is not</span> final.</p>
                <Button
                    label="Add to Queue"
                    icon="pi pi-plus"
                    @click="handleAddToTransactionQueue"
                    :loading="isAddingToTransactionQueue"
                    class="p-button-raised queue-button"
                />
              </div>
            </div>

            <!-- Polling Queue Card -->
            <div class="queue-operation-card">
              <div class="queue-icon-wrapper">
                <i class="pi pi-sync"></i>
              </div>
              <div class="queue-content">
                <h4>Polling Queue</h4>
                <p>Add the transaction to the polling queue to request the latest status from the payment provider.
                  Before adding, ensure that the transaction status <span class="highlighted-text">is not</span>
                  final and that the provider supports polling.</p>
                <Button
                    label="Add to Queue"
                    icon="pi pi-plus"
                    @click="handleAddToPollingQueue"
                    :loading="isAddingToPollingQueue"
                    class="p-button-raised queue-button"
                />
              </div>
            </div>

            <!-- Callback Queue Card -->
            <div class="queue-operation-card">
              <div class="queue-icon-wrapper">
                <i class="pi pi-bell"></i>
              </div>
              <div class="queue-content">
                <h4>Callback Queue</h4>
                <p>Add the transaction to the callback queue to resend the transaction result to the merchant's callback URL.
                  Before adding, ensure that the transaction status <span class="highlighted-text">is</span> final.</p>
                <Button
                    label="Add to Queue"
                    icon="pi pi-plus"
                    @click="handleAddToCallbackQueue"
                    :loading="isAddingToCallbackQueue"
                    class="p-button-raised queue-button"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Toast position="bottom-right" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import { useToast } from "primevue/usetoast";

export default {
  name: 'EditTransaction',
  components: {
    Dropdown,
    Button,
    Toast
  },
  props: {
    transactionId: {
      type: [Number, String],
      required: true
    },
    currentStatusId: {
      type: [Number, String],
      required: true
    },
    isDarkTheme: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      isLoading: false,
      error: null,
      selectedStatus: null,
      isUpdatingStatus: false,
      isAddingToTransactionQueue: false,
      isAddingToPollingQueue: false,
      isAddingToCallbackQueue: false,
      transactionStatusOptions: [
        { label: 'Success', value: 4 },
        { label: 'Pending', value: 3 },
        { label: 'Scheduled', value: 2 },
        { label: 'Not Confirmed', value: 1 },
        { label: 'Cancelled', value: -1 },
        { label: 'PSP Error', value: -2 },
        { label: 'PSP Generic', value: -3 },
        { label: 'Network Error', value: -4 },
        { label: 'Internal Error', value: -5 },
        { label: 'Validation Failed', value: -6 },
        { label: 'Unknown', value: 0 },
      ],
      validationResults: [],
      isLoadingValidations: false,
      validationStatusOptions: [
        { label: 'Success', value: 1 },
        { label: 'Pending', value: 0 },
        { label: 'Failed', value: -1 }
      ],
      isUpdatingValidation: null,
      isRetryingValidation: null,
    };
  },
  created() {
    this.fetchValidationResults();
  },
  methods: {
    ...mapActions('transactions', [
      'updateTransactionStatus',
      'addToTransactionQueue',
      'addToPollingQueue',
      'addToCallbackQueue',
      'getValidationResults',
      'setValidationResult',
      'retryAwaitableValidation'
    ]),

    async fetchValidationResults() {
      this.isLoadingValidations = true;
      try {
        const response = await this.getValidationResults(this.transactionId);
        this.validationResults = response.data;
      } catch (error) {
        this.toast.add({
          severity: 'error',
          summary: 'Failed to Load',
          detail: `Could not load validation results: ${error.message}`,
          life: 5000
        });
      } finally {
        this.isLoadingValidations = false;
      }
    },

    async handleSetValidationStatus(validationId, newStatus) {
      this.isUpdatingValidation = validationId;
      try {
        await this.setValidationResult({ validationId, status: newStatus });
        await this.fetchValidationResults(); // Refresh the list
        this.toast.add({
          severity: 'success',
          summary: 'Status Updated',
          detail: 'Validation status was successfully updated',
          life: 3000
        });
      } catch (error) {
        this.toast.add({
          severity: 'error',
          summary: 'Update Failed',
          detail: `Failed to update validation status: ${error.message}`,
          life: 5000
        });
      } finally {
        this.isUpdatingValidation = null;
      }
    },

    async handleRetryValidation(validationId) {
      this.isRetryingValidation = validationId;
      try {
        await this.retryAwaitableValidation(validationId);
        await this.fetchValidationResults(); // Refresh the list
        this.toast.add({
          severity: 'success',
          summary: 'Validation Retried',
          detail: 'Validation retry was triggered successfully',
          life: 3000
        });
      } catch (error) {
        this.toast.add({
          severity: 'error',
          summary: 'Retry Failed',
          detail: `Failed to retry validation: ${error.message}`,
          life: 5000
        });
      } finally {
        this.isRetryingValidation = null;
      }
    },

    getValidationStatusClass(status) {
      if (status === 1) return 'status-success';
      if (status === 0) return 'status-pending';
      return 'status-error';
    },

    getStatusLabel(status) {
      const option = this.transactionStatusOptions.find(option => option.value === parseInt(status));
      return option ? option.label : 'Unknown';
    },

    getStatusClass(status) {
      const statusInt = parseInt(status);
      if (statusInt === 4) return 'status-success';
      if (statusInt > 0) return 'status-pending';
      if (statusInt <= 0) return 'status-error';
      return 'status-unknown';
    },

    async handleStatusUpdate() {
      if (!this.selectedStatus) return;

      this.isUpdatingStatus = true;
      try {
        await this.updateTransactionStatus({
          transactionId: this.transactionId,
          status: this.selectedStatus
        });
        this.toast.add({
          severity: 'success',
          summary: 'Status Updated',
          detail: `Transaction status successfully updated to ${this.getStatusLabel(this.selectedStatus)}`,
          life: 3000
        });
      } catch (error) {
        this.toast.add({
          severity: 'error',
          summary: 'Update Failed',
          detail: `Failed to update status: ${error.message}`,
          life: 5000
        });
      } finally {
        this.isUpdatingStatus = false;
      }
    },

    async handleAddToTransactionQueue() {
      this.isAddingToTransactionQueue = true;
      try {
        const response = await this.addToTransactionQueue(this.transactionId);
        if (response?.status === 202) {
          this.toast.add({
            severity: 'success',
            summary: 'Added to Queue',
            detail: 'Transaction successfully added to processing queue',
            life: 3000
          });
        }
      } catch (error) {
        this.toast.add({
          severity: 'error',
          summary: 'Queue Operation Failed',
          detail: `Failed to add to transaction queue: ${error.message}`,
          life: 5000
        });
      } finally {
        this.isAddingToTransactionQueue = false;
      }
    },

    async handleAddToPollingQueue() {
      this.isAddingToPollingQueue = true;
      try {
        const response = await this.addToPollingQueue(this.transactionId);
        if (response?.status === 202) {
          this.toast.add({
            severity: 'success',
            summary: 'Added to Queue',
            detail: 'Transaction successfully added to polling queue',
            life: 3000
          });
        }
      } catch (error) {
        this.toast.add({
          severity: 'error',
          summary: 'Queue Operation Failed',
          detail: `Failed to add to polling queue: ${error.message}`,
          life: 5000
        });
      } finally {
        this.isAddingToPollingQueue = false;
      }
    },

    async handleAddToCallbackQueue() {
      this.isAddingToCallbackQueue = true;
      try {
        const response = await this.addToCallbackQueue(this.transactionId);
        if (response?.status === 202) {
          this.toast.add({
            severity: 'success',
            summary: 'Added to Queue',
            detail: 'Transaction successfully added to callback queue',
            life: 3000
          });
        }
      } catch (error) {
        this.toast.add({
          severity: 'error',
          summary: 'Queue Operation Failed',
          detail: `Failed to add to callback queue: ${error.message}`,
          life: 5000
        });
      } finally {
        this.isAddingToCallbackQueue = false;
      }
    },

    getStatusIcon(status) {
      const statusInt = parseInt(status);
      if (statusInt === 4) return 'pi-check-circle';
      if (statusInt > 0) return 'pi-clock';
      if (statusInt <= 0) return 'pi-times-circle';
      return 'pi-question-circle';
    },
  }
};
</script>

<style scoped>

/* Base Layout */
.edit-transaction {
  padding: 2rem;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background-color: var(--surface-ground);
}

.edit-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

/* Loading States */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  gap: 1.5rem;
}

.loading-spinner {
  font-size: 3rem;
  color: var(--primary-color);
}

.loading-text {
  font-size: 1.25rem;
  color: var(--text-color-secondary);
  font-weight: 500;
}

/* Card Styles */
.info-card {
  background-color: var(--surface-card);
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
  transition: all 0.2s ease-in-out;
}

.info-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--surface-border);
  gap: 1rem;
}

.header-icon {
  font-size: 1.3rem;
  color: var(--primary-color);
  flex-shrink: 0;
}

.card-header h3 {
  margin: 0;
  font-size: 1.1rem;
  color: var(--text-color);
  font-weight: 600;
  flex-grow: 1;
}

.refresh-button {
  margin-left: auto;
  width: 2.5rem;
  height: 2.5rem;
  flex-shrink: 0;
}

.status-dropdown {
  flex-grow: 0;
}

/* Status Badges */

.status-success {
  background-color: var(--green-50);
  color: var(--green-800);
}

.status-pending {
  background-color: var(--yellow-50);
  color: var(--yellow-800);
}

.status-error {
  background-color: var(--red-50);
  color: var(--red-800);
}

.status-unknown {
  background-color: var(--surface-200);
  color: var(--surface-800);
}

/* Validation Section */

.validation-list {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.validation-item {
  background-color: var(--surface-section);
  border-radius: 0.75rem;
  padding: 1.5rem;
  transition: all 0.2s ease;
}

.validation-item:hover {
  background-color: var(--surface-hover);
}

.url-text {
  font-family: 'Fira Code', 'Courier New', monospace;
  background-color: var(--surface-ground);
  padding: 0.375rem 0.75rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  letter-spacing: -0.01em;
  word-wrap: anywhere;
  white-space: pre-wrap;
  margin-left: 0.5rem;
}

.validation-dropdown {
  min-width: 200px;
}

/* Queue Operations */

.queue-info h4 {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin: 0 0 0.5rem 0;
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--text-color);
}

.queue-info p {
  margin: 0;
  color: var(--text-color-secondary);
  line-height: 1.5;
}

.highlighted-text {
  font-weight: 700;
  color: var(--primary-color);
}

.queue-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  width: 100%;
}

.queue-operation-card {
  background-color: var(--surface-section);
  border-radius: 1rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: all 0.2s ease;
  border: 1px solid var(--surface-border);
}

.queue-operation-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-color: var(--primary-color);
}

.queue-icon-wrapper {
  background: var(--primary-color);
  width: 3rem;
  height: 3rem;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.queue-icon-wrapper i {
  font-size: 1.5rem;
  color: white;
}

.queue-content {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  flex: 1;
}

.queue-content h4 {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--text-color);
  margin: 0;
}

.queue-content p {
  color: var(--text-color-secondary);
  font-size: 0.9375rem;
  line-height: 1.6;
  margin: 0;
  flex: 1;
}

.queue-button {
  align-self: flex-start;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
}

.queue-button:hover {
  transform: translateY(-2px);
}

/* Dark Theme Adjustments */
:deep(.dark-theme) {
  .queue-operation-card {
    background-color: var(--surface-card);
    border-color: var(--surface-border);
  }

  .queue-operation-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .queue-icon-wrapper {
    background: var(--primary-700);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .queue-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .queue-operation-card {
    padding: 1.25rem;
  }

  .queue-button {
    width: 100%;
    justify-content: center;
  }
}

/* Empty States */
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 2rem;
  gap: 1rem;
  color: var(--text-color-secondary);
  text-align: center;
}

.empty-icon {
  font-size: 2.5rem;
  opacity: 0.5;
  margin-bottom: 0.5rem;
}

.padding-left {
  padding-left: 0.5rem;
}

/* Dark Theme Adjustments */
.dark-theme {
  .info-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .status-success {
    background-color: var(--green-900);
    color: var(--green-100);
  }

  .status-pending {
    background-color: var(--yellow-900);
    color: var(--yellow-100);
  }

  .status-error {
    background-color: var(--red-900);
    color: var(--red-100);
  }

  .status-unknown {
    background-color: var(--surface-700);
    color: var(--surface-50);
  }
}

/* Responsive Design */
@media (max-width: 1024px) {
  .edit-transaction {
    padding: 1.5rem;
  }

  .info-card {
    padding: 1.5rem;
  }
}

@media (max-width: 768px) {
  .edit-transaction {
    padding: 1rem;
  }

  .info-card {
    padding: 1.25rem;
  }

  .card-header {
    margin-bottom: 1.5rem;
  }
}

/* Status Update */
.header-icon-container {
  background: var(--primary-color);
  border-radius: 50%;
  padding: 1rem;
  display: flex;
  margin-right: .2rem;
}

.header-icon {
  font-size: 1.75rem;
  color: white;
}

.card-subtitle {
  color: var(--text-color-secondary);
  font-size: 0.9rem;
  margin-top: 0.25rem;
}

.state-container {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 2rem;
  margin: 2rem 0;
}

.state-pair {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.status-indicator {
  display: inline-flex;
  align-items: center;
  padding: 0.52rem 1.3rem;
  border-radius: 2rem;
  font-weight: 600;
  position: relative;
  transition: transform 0.2s ease;
}

.status-indicator:hover {
  transform: translateY(-2px);
}

.status-glow {
  position: absolute;
  inset: 0;
  border-radius: 2rem;
  opacity: 0.1;
  z-index: -1;
}

.status-success .status-glow { background: var(--green-500); }
.status-pending .status-glow { background: var(--yellow-500); }
.status-error .status-glow { background: var(--red-500); }

.transition-arrow {
  font-size: 1.5rem;
  color: var(--text-color-secondary);
  margin-top: 1.5rem;
}

/* Validation Results */
.validation-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.validation-type {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: var(--text-color-secondary);
}

.validation-id {
  font-family: monospace;
  font-weight: 600;
}

.validation-endpoint {
  display: flex;
  align-items: center;
  gap: 0rem;
}

.url-text {
  font-family: 'Fira Code', monospace;
  font-size: 0.8rem;
}

.validation-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.control-group {
  display: flex;
  gap: 0.75rem;
}

.status-tag {
  padding: 0.375rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.8rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

/* Dark Theme Adjustments */
.dark-theme {
  .status-indicator {
    box-shadow: 0 4px 16px rgba(0,0,0,0.2);
  }
}

</style>
